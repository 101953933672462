/* eslint-disable */
import { bus } from '@/main';
const $ = window.jQuery = require('jquery');  
export default {
  name: 'about-us-page',
  components: {},
  props: [],
  data () {
    return {
      authBtnShow:true,
    }
  },
  computed: {

  },
  mounted () {
    bus.$emit('showHeaderbtn', false);
    bus.$emit('showHeaderSection', false);
    if(this.$store.getters.isAuthenticated){
      this.authBtnShow = false
    }
  },
  methods: {
  },
  beforeCreate: function() {
    $('body').addClass('dashboard')
    $('body').addClass('public_page_for_wrp_img')
  },
  beforeRouteLeave: function (to, from, next) {
    $('body').removeClass('dashboard')
    $('body').removeClass('public_page_for_wrp_img')
    next();
  },
}